// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$WidgetPortalClient-primary: mat.define-palette(mat.$indigo-palette);
$WidgetPortalClient-accent: mat.define-palette(mat.$teal-palette);

// The warn palette is optional (defaults to red).
$WidgetPortalClient-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$WidgetPortalClient-theme: mat.define-light-theme($WidgetPortalClient-primary, $WidgetPortalClient-accent, $WidgetPortalClient-warn);

$WidgetPortalClient-typography: mat.define-typography-config(
  $font-family: 'Roboto, Helvetica Neue',
  $headline: mat.define-typography-level(32px, 48px, 700),
  $body-1: mat.define-typography-level(16px, 24px, 400)
);

$error-color: #E65050;
$success-color: #4BB543;
$warning-color: #FFC841;
$inactive-color: #9D9FAC;

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($WidgetPortalClient-theme);
@include mat.core($WidgetPortalClient-typography);


html, body { height: 100%; }
body {
  margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

mat-button-toggle:not(.mat-button-toggle-disabled) {
  background-color: #E65050;

  &:hover {
    background-color: #D22929;
  }

  &.mat-button-toggle-checked {
    background-color: #4BB543;

    &:hover {
      background-color: #2AA324;
    }
  }

  &.circle-toggle {
    height: 20px;
    width: 20px;
    border-radius: 50%;
  }
}

.button-spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #3f51b5;
  animation: spinner .8s linear infinite;
}

.spinner-container{
  margin: auto;
  max-width: 100px;
  padding: 15px;
}

.inactive {
  background-color: $inactive-color;
}

.error-snack {
  background-color: $error-color;
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;

  .mat-simple-snackbar-action {
    color: inherit;
  }
}

.success-snack {
  background-color: $success-color;
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;

  .mat-simple-snackbar-action {
    color: inherit;
  }
}

.warning-snack {
  background-color: $warning-color;
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;

  .mat-simple-snackbar-action {
    color: inherit;
  }
}

.success-text {
  color: #4BB543;
}

.mat-header-cell {
  flex-direction: column;
  justify-content: center;
}

.mat-cell {
  text-align: center;
  justify-content: center;
  word-break: break-all;
}

.table-header-container{
  margin-bottom: 10px;
}

.full-width-element {
  width: 100%;
}
